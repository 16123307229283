import ky from 'ky';

import { PDFDocument, PDFName } from 'pdf-lib';
import JSZip from 'jszip';
import MP3Tag from 'mp3tag.js';

export const UPLOAD_ALLOWED_FORMATS = [
  'doc',
  'pdf',
  'jpg',
  'png',
  'docx',
  'ppt',
  'pptx',
  'xlsx',
  'mp3',
  'ogg',
];
export const MIME_TYPE = {
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PDF: 'application/pdf',
  MP3: 'audio/mpeg',
  OGG: 'audio/ogg',
};
export const UPLOAD_SIZE_LIMIT_PER_FILE = 10485760;

export const lowercaseFileExtension = (filename) => {
  const regexResult = /[.]/.exec(filename)
    ? /[^.]+$/.exec(filename)
    : undefined;
  if (!regexResult) {
    return filename;
  }
  const fileExtension = regexResult[0];
  return filename.replace(
    new RegExp(fileExtension + '$'),
    fileExtension.toLowerCase()
  );
};

export const isAudioFile = (filename) =>
  filename.split('.').some((key) => key === 'mp3' || key === 'ogg');

export const putPresignedUrl = async (url, file) => {
  file = await maybeRemoveMetadata(file);
  return await ky.put(url, {
    body: file,
  });
};

export const postPresignedUrl = async (pUrl, file) => {
  file = await maybeRemoveMetadata(file);

  const formData = new FormData();
  Object.keys(pUrl.fields)
    .filter((key) => key !== 'file')
    .forEach((key) => formData.append(key, pUrl.fields[key]));
  formData.append('file', file);

  return await ky.post(pUrl.url, { body: formData }).catch(console.error);
};

async function maybeRemoveMetadata(file) {
  switch (file.type) {
    case MIME_TYPE.DOCX:
    case MIME_TYPE.XLSX:
    case MIME_TYPE.PPTX:
      return await removeMetadataFromOfficeDoc(file);
    case MIME_TYPE.JPEG:
    case MIME_TYPE.JPG:
      return await removeExifFromImage(file);
    case MIME_TYPE.PDF:
      return await removeMetadataFromPdf(file);
    case MIME_TYPE.MP3:
      return await removeID3tags(file);
    default:
      return file;
  }
}

async function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

async function removeExifFromImage(file) {
  const arrayBuffer = await blobToArrayBuffer(file);
  const newBlobs = removeExif(arrayBuffer, new DataView(arrayBuffer));
  return !newBlobs ? file : new File(newBlobs, file.name, { type: file.type });
}

function removeExif(imageArrayBuffer, dv) {
  let offset = 0;
  if (dv.getUint16(offset) !== 0xffd8) {
    return null;
  }

  offset += 2;
  let app1 = dv.getUint16(offset);
  offset += 2;

  let pieces = [];
  let i = 0;
  let recess = 0;
  while (offset < dv.byteLength) {
    if (app1 === 0xffe1) {
      pieces[i] = { recess, offset: offset - 2 };
      recess = offset + dv.getUint16(offset);
      i++;
    } else if (app1 == 0xffda) {
      break;
    }
    offset += dv.getUint16(offset);
    app1 = dv.getUint16(offset);
    offset += 2;
  }
  if (pieces.length > 0) {
    let newPieces = [];
    pieces.forEach(function (v) {
      newPieces.push(imageArrayBuffer.slice(v.recess, v.offset));
    }, this);
    newPieces.push(imageArrayBuffer.slice(recess));
    return newPieces;
  }
}

async function removeMetadataFromPdf(file) {
  const arrayBuffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  const pdfDoc = await PDFDocument.load(uint8Array);

  pdfDoc.setTitle('');
  pdfDoc.setSubject('');
  pdfDoc.setAuthor('');
  pdfDoc.setCreator('');
  pdfDoc.setProducer('');
  pdfDoc.setKeywords(['']);
  pdfDoc.setProducer('');

  clearXMPMetadata(pdfDoc);

  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], file.name, { type: file.type });
}

async function removeID3tags(file) {
  const buffer = await blobToArrayBuffer(file);
  const verbose = false;
  const mp3tag = new MP3Tag(buffer, verbose);
  mp3tag.remove();
  if (mp3tag.error !== '') throw new Error(mp3tag.error);
  const updatedBuffer = mp3tag.buffer;

  return new File([updatedBuffer], file.name, { type: file.type });
}

const clearXMPMetadata = (pdfDoc) => {
  const whitespacePadding = new Array(20).fill(' '.repeat(100)).join('\n');

  const metadataXML = `
    <?xpacket begin="" id="W5M0MpCehiHzreSzNTczkc9d"?>
      <x:xmpmeta xmlns:x="adobe:ns:meta/" x:xmptk="Adobe XMP Core 5.2-c001 63.139439, 2010/09/27-13:37:26        ">
        <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">

          <rdf:Description rdf:about="" xmlns:dc="http://purl.org/dc/elements/1.1/">
            <dc:format>application/pdf</dc:format>
            <dc:creator>
              <rdf:Seq>
                <rdf:li></rdf:li>
              </rdf:Seq>
            </dc:creator>
            <dc:title>
               <rdf:Alt>
                  <rdf:li xml:lang="x-default"></rdf:li>
               </rdf:Alt>
            </dc:title>
            <dc:subject>
              <rdf:Bag>
              </rdf:Bag>
            </dc:subject>
          </rdf:Description>

          <rdf:Description rdf:about="" xmlns:xmp="http://ns.adobe.com/xap/1.0/">
            <xmp:CreatorTool></xmp:CreatorTool>
            <xmp:CreateDate></xmp:CreateDate>
            <xmp:ModifyDate></xmp:ModifyDate>
          </rdf:Description>

          <rdf:Description rdf:about="" xmlns:pdf="http://ns.adobe.com/pdf/1.3/">
            <pdf:Subject></pdf:Subject>
            <pdf:Producer></pdf:Producer>
            <pdf:Author></pdf:Author>
          </rdf:Description>

        </rdf:RDF>
      </x:xmpmeta>
      ${whitespacePadding}
    <?xpacket end="w"?>
  `.trim();

  const metadataStream = pdfDoc.context.stream(metadataXML, {
    Type: 'Metadata',
    Subtype: 'XML',
    Length: metadataXML.length,
  });

  const metadataStreamRef = pdfDoc.context.register(metadataStream);

  pdfDoc.catalog.set(PDFName.of('Metadata'), metadataStreamRef);
};

const removeMetadataFromOfficeDoc = async (file) => {
  const cleanFile = await removeDataFromXml(file);

  return new File([cleanFile], file.name, { type: file.type });
};

const removeDataFromXml = (officeFile) => {
  return loadFile(officeFile)
    .then(function (zip) {
      if (zip.OPformat === 'office') {
        zip.remove('docProps/core.xml');
        zip.remove('docProps/app.xml');
        if (
          Object.prototype.hasOwnProperty.call(zip.files, 'docProps/custom.xml')
        ) {
          zip.remove('docProps/custom.xml');
        }
        const appXML =
          '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Properties xmlns="http://schemas.openxmlformats.org/officeDocument/2006/extended-properties" xmlns:vt="http://schemas.openxmlformats.org/officeDocument/2006/docPropsVTypes"></Properties>';
        const coreXML =
          '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><cp:coreProperties xmlns:cp="http://schemas.openxmlformats.org/package/2006/metadata/core-properties" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:dcmitype="http://purl.org/dc/dcmitype/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"></cp:coreProperties>';
        zip.file('docProps/core.xml', coreXML);
        zip.file('docProps/app.xml', appXML);
      } else if (zip.OPformat === 'openoffice') {
        zip.remove('meta.xml');
        const metaXML =
          '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><office:document-meta xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0" xmlns:meta="urn:oasis:names:tc:opendocument:xmlns:meta:1.0" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:xlink="http://www.w3.org/1999/xlink" office:version="1.1"></office:document-meta>';
        zip.file('meta.xml', metaXML);
      } else {
        throw new Error('File not valid');
      }
      return generateZipFile(zip, officeFile);
    })
    .catch((e) => {
      throw new Error(e);
    });
};

const loadFile = async (officeFile) => {
  return await JSZip.loadAsync(officeFile).then((zip) => {
    const format = getFormat(zip);

    if (format) {
      zip.OPformat = format;
      return zip;
    } else {
      throw new Error('Error loading Office file.');
    }
  });
};

const getFormat = (zip) => {
  if (
    Object.prototype.hasOwnProperty.call(zip.files, 'docProps/core.xml') &&
    Object.prototype.hasOwnProperty.call(zip.files, 'docProps/app.xml')
  ) {
    return 'office';
  } else if (Object.prototype.hasOwnProperty.call(zip.files, 'meta.xml')) {
    return 'openoffice';
  }
  return false;
};

const generateZipFile = async (zip, originalFile) => {
  return await zip.generateAsync({
    mimeType: originalFile.type,
    type: 'blob',
  });
};
