import ky from 'ky';

import {
  getAccessToken,
  getIdentityToken,
} from '@wb/shared/utils/sessionStorage';

const PREFIX_URL = '/api/v1';
const COGNITO_KEY_PREFIX = 'CognitoIdentityServiceProvider';
const ENDPOINTS_WITHOUT_AUTH = ['init'];
const TIMEOUT = 30000;

const kyInstance = ky.extend({
  prefixUrl: PREFIX_URL,
  timeout: TIMEOUT,
  hooks: {
    beforeRequest: [
      (options) => {
        const { REACT_APP_COGNITO_CLIENT_ID, PUBLIC_URL } = process.env;
        let headers = {},
          data = {};

        const shouldHaveAuthHeaders =
          ENDPOINTS_WITHOUT_AUTH.map(
            (endpoint) => options.url.indexOf(`${PREFIX_URL}/${endpoint}`) > -1
          ).filter((entry) => entry === true).length === 0;

        if (!shouldHaveAuthHeaders) return;

        switch (PUBLIC_URL) {
          case '/admin':
          case '/backoffice':
            data = Object.entries(localStorage)
              .filter(
                ([key]) =>
                  key.startsWith(
                    `${COGNITO_KEY_PREFIX}.${REACT_APP_COGNITO_CLIENT_ID}`
                  ) &&
                  ['LastAuthUser', 'accessToken', 'idToken'].indexOf(
                    key.split('.').pop()
                  ) > -1
              )
              .reduce((acc, [key, value]) => {
                acc[key.split('.').pop()] = value;
                return acc;
              }, {});

            headers.Authorization = data?.accessToken
              ? `Bearer ${data?.accessToken}`
              : null;

            headers.identity = data?.idToken || null;
            if (!headers.identity && !headers.Authorization)
              throw new Error('Not authorized');
            break;
          default:
            headers.Authorization = getAccessToken();
            headers.identity = getIdentityToken();
            break;
        }

        Object.keys(headers)
          .filter((key) => key !== null)
          .forEach((key) => options.headers.set(key, headers[key]));
      },
    ],
    beforeError: [
      (error) => {
        const { PUBLIC_URL } = process.env;
        if (error.response.status !== 401 || PUBLIC_URL !== '/backoffice')
          throw error;
        location.href = location.origin + PUBLIC_URL;
        throw error;
      },
    ],
  },
});

export default kyInstance;
